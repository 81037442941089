<template>
  <div class="box-tools" v-if="isReady">
    <span
      class="btn btn-box-tool"
      style="font-size: 0.9em"
      :class="{disabled: !canUndo}"
      :disabled="!canUndo"
      :title="$t('undo_action')"
      @click.stop.prevent="onClick('undo')"
    >
      <i class="fa fa-reply fa-undo"></i>
    </span>
    <span
      class="btn btn-box-tool"
      style="font-size: 0.9em"
      :class="{disabled: !canRedo}"
      :disabled="!canRedo"
      :title="$t('redo_action')"
      @click.stop.prevent="onClick('redo')"
    >
      <i class="fa fa-reply fa-redo"></i>
    </span>
    <span
      class="btn btn-box-tool fa"
      style="font-size: 1.1em"
      :class="isPanelLocked ? 'fa-lock is-active' : 'fa-unlock'"
      :title="`${this.$t(
        `synoptic.${this.isPanelLocked ? 'unlock' : 'lock'}`
      )} ${this.$t('controls').toLowerCase()}`"
      @click.stop.prevent="onClick('toggleLockControls')"
    />
    <span
      class="btn btn-box-tool fa fa-hand-pointer-o"
      style="padding-top: 4px"
      :class="areControlsSelectable ? 'is-active' : ''"
      :title="
        $t(
          `synoptic.${
            areControlsSelectable ? 'disable_select_mode' : 'enable_select_mode'
          }`
        )
      "
      @click.stop.prevent="onClick('toggleSelectToChange')"
    />
  </div>
</template>

<script>
import {debounce} from "lodash";
export default {
  name: "SynopticEditorToolbar",
  data() {
    return {
      canUndo: false,
      canRedo: false,
      panelName: ""
    };
  },
  computed: {
    panel() {
      return this.$store.getters["synoptic/panel"];
    },
    panelControls() {
      return this?.panel?.options?.controls;
    },
    isPanelLocked() {
      return this?.panel?.locked;
    },
    areControlsSelectable() {
      return this.$store.getters["synoptic/selectToChange"];
    },
    isReady() {
      return this.panel && this.panel.name == this.panelName;
    }
  },
  watch: {
    panelControls: {
      handler(n, o) {
        if (!this.isReady) return;
        if (this.skip) {
          this.skip = false;
          return;
        } else if (n && o) this._save(o);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    save(value) {
      let o =
        (this.undoList.length &&
          JSON.stringify(this.undoList[this.undoList.length - 1])) ||
        "";
      let n = JSON.stringify(value);
      if (o != n) {
        this.undoList.push(JSON.parse(n));
        this.validate();
      }
    },
    validate() {
      this.canUndo = this.undoList.length ? true : false;
      this.canRedo = this.redoList.length ? true : false;
    },
    undo() {
      if (!this.canUndo) return;
      if (this.undoList.length) {
        this.redoList.push(this.undoList.pop());
        this.$store.dispatch(
          "synoptic/setControls",
          this.undoList.length
            ? this.undoList[this.undoList.length - 1]
            : this.original
        );
        this.skip = true;
      }
      this.validate();
    },
    redo() {
      if (!this.canRedo) return;
      if (this.redoList.length) {
        this.$store.dispatch(
          "synoptic/setControls",
          this.redoList.length
            ? this.redoList[this.redoList.length - 1]
            : this.original
        );
        this.undoList.push(this.redoList.pop());
        this.skip = true;
      }
      this.validate();
    },
    onClick(action) {
      switch (action) {
        case "undo":
          this.$root.$emit("undo");
          break;
        case "redo":
          this.$root.$emit("redo");
          break;
        case "toggleLockControls":
          this.$store.dispatch("synoptic/toggleLockControls");
          break;
        case "toggleSelectToChange":
          this.$store.dispatch("synoptic/toggleSelectToChange");
          break;
      }
    }
  },
  beforeCreate() {
    // non reactive properties
    this.original = [];
    this.undoList = [];
    this.redoList = [];
    this.skip = false;
    this._save = debounce((payload) => {
      this.save(payload);
    }, 500);
  },
  created() {
    this.$nextTick(() => {
      let tm = setInterval(
        () => {
          if (this.panel) {
            clearInterval(tm);
            tm = null;
            this.panelName = this.panel.name;
            this.original = JSON.parse(JSON.stringify(this.panelControls));
            this.$root.$on("undo", this.undo);
            this.$root.$on("redo", this.redo);
          }
        },
        100,
        this
      );
    });
  },
  beforeDestroy() {
    this.$root.$off("undo", this.undo);
    this.$root.$off("redo", this.redo);
  }
};
</script>

<style lang="scss" scoped>
.box-tools .btn:hover {
  cursor: pointer;
  background-color: #e6e6e6;
  color: #337ab7;
}

.skin-dark .box-tools .btn:hover {

}

.skin-dark .box-tools .btn:hover {
  background-color: transparent;
  color: #fff;
}

.fa-redo {
  transform: rotateY(180deg);
}

.btn-box-tool {
  padding: 2px 5px;
  width: 24px;
  height: 24px;
  margin-inline: 1px;
  color: #333;
}

.skin-dark .btn-box-tool {
  color: var(--skin-dark-light);
}

.box-tools .btn-box-tool {
  &:not(.is-active) {
    &:hover {
      background-color: #e6e6e6;
    }

    &:active {
      background-color: #d4d4d4;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  &.is-active {
    background-color: #3c8dbc;

    &:hover {
      background-color: #367fa9;
    }

    &:active {
      background-color: #204d74;
    }

    i {
      color: white;
    }
  }
}

.skin-dark .box-tools .btn-box-tool {
  &:not(.is-active) {
    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }

    &:disabled, &.disabled {
      background-color: transparent;

      & i {
        color: var(--skin-dark-dark);
      }
    }

    &:not(:disabled,.disabled) i {
      color: var(--skin-dark-light);
    }
  }

  &.is-active {
    color: var(--skin-dark-darker);
    background-color: var(--skin-dark-light);

    &:hover {
      color: var(--skin-dark-darker);
      background-color: var(--skin-dark-light);
    }

    &:active {
      color: var(--skin-dark-darker);
      background-color: var(--skin-dark-light);
    }

    i {
      color: #fff;
    }
  }
}
</style>